import { makeStyles } from 'tss-react/mui';

import FORM_SIZE from 'constants/window';
import COLORS from 'theme/colors';
import FONT_FAMILIES from 'theme/fontFamilies';

const useStyles = makeStyles<{ isOverlay?: boolean }>()((theme, { isOverlay }) => ({
  rootLogos: {
    display: 'flex',
    flexDirection: 'row',
    position: 'fixed',
    top: 30,
    left: 30,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& img:nth-of-type(1)': {
      minHeight: 30,
    },
    '& img:nth-of-type(2)': {
      minHeight: 20,
    },
  },
  rootLogosSeparator: {
    borderLeft: `1px solid ${COLORS.GREY}`,
    margin: '0 20px',
  },
  outerContainer: {
    position: 'relative',
    maxWidth: FORM_SIZE.VERSION_2.WIDTH,
    width: '100vw',
    minWidth: 280,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: isOverlay ? 'center' : 'flex-start',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
      height: `min(${FORM_SIZE.VERSION_2.HEIGHT}px, 100vh)`,
    },
    [`@media (max-height: ${FORM_SIZE.VERSION_2.HEIGHT}px)`]: {
      '#root': {
        alignItems: 'flex-start',
      },
    },
    '& + div': {
      maxWidth: FORM_SIZE.VERSION_2.WIDTH,
    },
  },
  innerContainer: {
    position: 'relative',
    width: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    width: '100%',
    height: 36,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    border: `1px solid ${COLORS.LIGHT_GREY}`,
    textDecoration: 'none',
  },
  cryptoFooter: {
    backgroundColor: COLORS.GREY_BACKGROUND,
    '& > img': {
      height: 20,
    },
    '& > span': {
      color: '#929292',
      marginRight: 6,
      fontSize: 12,
      fontWeight: 400,
      fontFamily: FONT_FAMILIES.NOTO_SANS,
    },
  },
  shift4Footer: {
    backgroundColor: COLORS.GREY_BACKGROUND,
    '& > img': {
      height: 15,
    },
    '& > span': {
      color: '#929292',
      marginRight: 6,
      fontSize: 12,
      fontWeight: 400,
      fontFamily: FONT_FAMILIES.NOTO_SANS,
    },
  },
  geminiFooter: {
    backgroundColor: COLORS.WHITE,
    '& > img': {
      height: 16,
    },
    '& > span': {
      color: '#000000',
      marginRight: 8,
      fontFamily: FONT_FAMILIES.INTER,
      fontSize: 15,
      fontWeight: 500,
    },
  },
  contentWrapper: {
    height: 'auto',
    position: 'relative',
  },
  topBarContainer: {
    height: 58,
    width: '100%',
    fontSize: 12,
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${COLORS.INPUT.BORDER}`,
  },
  charityNameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    maxWidth: 370,
  },
  imageContainer: {
    width: 30,
    height: 30,
    borderRadius: 20,
    overflow: 'hidden',
    alignContent: 'center',
    flexShrink: 0,
  },
  image: {
    height: 'auto',
    width: 30,
  },
  charityName: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '18px',
    cursor: 'default',
    pointerEvents: 'none',
    color: theme.palette.text.secondary,
  },
  charityNameLong: {
    fontSize: 10,
  },
  poweredContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 8,
    width: 'calc(100% - 12px)',
    margin: '0 6px',
  },
  poweredPrompt: {
    fontSize: 12,
  },
  paper: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    boxSizing: 'border-box',
    boxShadow: '0 3px 12px rgba(41, 27, 79, 0.05) !important',
    minWidth: 280,
    width: '100vw',
    maxWidth: FORM_SIZE.VERSION_2.WIDTH,
    maxHeight: '100%',
    overflowY: 'auto',
    border: `1px solid ${COLORS.INPUT.BORDER}`,
  },
  helpButton: {
    position: 'absolute',
    right: 0,
    color: `${COLORS.GREY} !important`,
    fontFamily: FONT_FAMILIES.INTER,
    fontSize: 12,
    fontWeight: 400,
    height: 26,
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    alignItems: 'center',
    padding: '6px 9px !important',
  },
  dialogOverlay: {
    top: 0,
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.40)',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
  },
  dialog: {
    backgroundColor: 'white',
    width: 'calc(100% - 64px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
  },
  dialogHeader: {
    borderBottom: `1px solid ${COLORS.LIGHT_GREY}`,
    width: '100%',
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'row',
  },
  dialogContent: {
    paddingTop: 40,
    paddingBottom: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContentHeader: {
    color: COLORS.PRIMARY,
    textAlign: 'center',
    fontFamily: FONT_FAMILIES.INTER,
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 8,
  },
  dialogHeaderTextContainer: {
    width: 'calc(100% - 100px)',
    marginLeft: 50,
    textAlign: 'center',
    color: COLORS.PRIMARY,
    fontFamily: FONT_FAMILIES.INTER,
    fontSize: 18,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButtonContainer: {
    width: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 8,
  },
  closeButton: {
    width: 30,
    minWidth: 30,
    height: 30,
    minHeight: 30,
    padding: 0,
    flex: 0,
    position: 'absolute',
  },
  arrowIcon: {
    marginLeft: 8,
  },
  supportLink: {
    color: COLORS.PRIMARY,
    textUnderlineOffset: 2,
    textAlign: 'center',
    fontFamily: FONT_FAMILIES.INTER,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    textDecorationLine: 'underline',
  },
  iconCopyWrapper: {
    marginLeft: 8,
    color: '#86909F',
    height: 20,
    width: 20,
  },
  emailCopyButton: {
    color: theme.palette.text.primary,
    flex: 1,
    fontFamily: 'Noto Sans',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    borderWidth: 0,
    background: 'transparent',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  emailContainer: {
    color: COLORS.PRIMARY,
    fontFamily: FONT_FAMILIES.INTER,
    fontSize: 16,
    fontWeight: 400,
  },
}));

export default useStyles;
